"use client";
import { Icon } from "@iconify/react";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";

const HomeSlider = ({ items, lang }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const currentIndexRef = useRef(currentIndex);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const isRTL = lang === "ar";

  const goToSlide = (index) => {
    setScrollEnabled(false);

    setCurrentIndex(index);
    currentIndexRef.current = index;

    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: index * sliderRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
    // Re-enable scroll after a short delay (adjust as necessary)
    setTimeout(() => {
      setScrollEnabled(true);
    }, 500);
  };

  const handleScroll = useCallback(() => {
    if (!scrollEnabled) return; // Prevent scroll handling when disabled

    const slider = sliderRef.current;
    if (!slider) return;

    const scrollPosition = slider.scrollLeft;
    const slideWidth = slider.offsetWidth;
    const newIndex = Math.round(scrollPosition / slideWidth);
    if (newIndex !== currentIndexRef.current) {
      currentIndexRef.current = newIndex;
      setCurrentIndex(newIndex);
    }
  }, [scrollEnabled]);

  const handleResize = useCallback(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.scrollTo({
        left: currentIndexRef.current * slider.offsetWidth,
        behavior: "auto",
      });
    }
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    slider.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial position

    return () => {
      slider.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleScroll, handleResize]);

  const Item = ({ item }) => {
    return item?.image && item?.slug ? (
      <Link
      className="h-full"
        key={item?._id}
        href={`/${lang}/news/article/${item?.slug}`}
        as={`/${lang}/news/article/${item?.slug}`}
      >
       <a className="block h-full">
          <Image
            src={item?.image}
            alt="Banner"
            layout="fill"
            objectFit="cover"
            // width={1230}
            // height={615}
            className="aspect-[2.6] rounded-button"
          />
        </a>
      </Link>
    ) : <div
    className="h-full"
      key={item?._id}
    >
     <a className="block h-full">
        <Image
          src={item?.image}
          alt="Banner"
          layout="fill"
          objectFit="cover"
          // width={1230}
          // height={615}
          className="aspect-video rounded-button"
        />
      </a>
    </div>;
  };
  return (
    <div className="relative w-full h-full">
      <div
        ref={sliderRef}
        style={{
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          WebkitOverflowScrolling: "touch",
        }}
        className="flex relative overflow-x-auto snap-x snap-mandatory scrollbar-hide h-full no-scrollbar"
      >
        {items?.map((item, index) => (
          <div key={index} className="flex-shrink-0 w-full h-full snap-center rounded">
            <Item item={item} />
          </div>
        ))}
      </div>
      {((isRTL ? -currentIndex:currentIndex) != items?.length - 1)  && (
        <div className="absolute z-10 left-5 bottom-5 top-0 flex flex-row justify-between items-center">
          <div
            onClick={() => {
              
              let newIndex = isRTL ?  currentIndex - 1 : currentIndex + 1
              goToSlide(newIndex);
            }}
            className="hover:scale-110 transition-all cursor-pointer bg-white w-8 h-8 rounded-button flex justify-center items-center"
          >
            <Icon icon={"ep:arrow-left-bold"} color="#000" className="" />
          </div>
        </div>
      )}
      {currentIndex != 0 && (
        <div className="absolute z-10 right-5 bottom-5 top-0 flex flex-row justify-between items-center">
          <div
            onClick={() => {
              let newIndex = isRTL ?currentIndex +1 : currentIndex - 1
              goToSlide(newIndex);
            }}
            className="hover:scale-110 transition-all cursor-pointer bg-white w-8 h-8 rounded-button flex justify-center items-center"
          >
            <Icon icon={"ep:arrow-right-bold"} color="#000" className="" />
          </div>
        </div>
      )}
      {/* Bullet Navigation */}
      <div className={`hidden md:flex justify-center mt-0 md:mt-4`}>
        {(items || [])?.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(isRTL ? -1 * index : index)}
            className={`h-2 w-2 rounded-full mx-1 focus:outline-none ${
              (isRTL ? -1 * currentIndex : currentIndex) === index
                ? "bg-orange"
                : "bg-oeaMain"
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeSlider;
