import { getTranslationContent } from "functions";
import Image from "next/image";
import Link from "next/link";
import React, { useState } from "react";
import Ticker from "react-ticker";

const NewsBar = ({ news = [], lang }) => {
  const [isPaused, setIsPaused] = useState(false);

  return (
    <Ticker
      offset={5}
      move={!isPaused}
      direction={lang == "ar" ? "toRight" : "toLeft"}
    >
      {({ index }) => {
        let isLast = index == news?.length - 1;
        return (
          <Link
            href={`/${lang}/news/article/[slug]`}
            as={`/${lang}/news/article/${news?.[index % news?.length]?.slug}`}
          >
            <a>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h4
                  className={"mx-10 hover:text-orange text-black"}
                  onMouseEnter={() => setIsPaused(true)}
                  onMouseLeave={() => setIsPaused(false)}
                >
                  {getTranslationContent(
                    news?.[index % news?.length],
                    "title",
                    lang
                  )}
                </h4>
                {isLast ? null : (
                  <div style={{ padding: "0 10px", textAlign: "center" }}>
                    <div className="hidden sm:block ">
                      <Image
                        src="/oea.png"
                        alt="OEA Logo"
                        width={16}
                        height={16}
                        className=" object-contain opacity-[0.09]"
                      />
                    </div>
                  </div>
                )}
              </div>
            </a>
          </Link>
        );
      }}
    </Ticker>
  );
};

export default NewsBar;
