"use client";
import { getTranslationContent } from "functions";
import { debounce } from "lodash";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";

const SliderNews = ({ items, lang }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);
  const currentIndexRef = useRef(currentIndex);
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const isRTL = lang === "ar";

  const goToSlide = useCallback((index) => {
    setScrollEnabled(false);

    setCurrentIndex(index);
    currentIndexRef.current = index;

    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: index * sliderRef.current.offsetWidth,
        behavior: "smooth",
      });
    }
    // Re-enable scroll after a short delay (adjust as necessary)
    setTimeout(() => {
      setScrollEnabled(true);
    }, 500);
  }, []);

  const handleScroll = useCallback(() => {
    if (!scrollEnabled) return; // Prevent scroll handling when disabled

    const slider = sliderRef.current;
    if (!slider) return;

    const scrollPosition = slider.scrollLeft;
    const slideWidth = slider.offsetWidth;
    const newIndex = Math.round(scrollPosition / slideWidth);
    if (newIndex !== currentIndexRef.current) {
      currentIndexRef.current = newIndex;
      setCurrentIndex(newIndex);
    }
  }, [scrollEnabled]);

  const handleResize = useCallback(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.scrollTo({
        left: currentIndexRef.current * slider.offsetWidth,
        behavior: "auto",
      });
    }
  }, []);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    slider.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    handleResize(); // Set initial position

    return () => {
      slider.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleScroll, handleResize]);

  const NewsItem = ({ announcement }) => {
    return (
      <Link
        className="mt-5"
        key={announcement?._id}
        href={`/${lang}/news/article/${announcement?.slug}`}
        as={`/${lang}/news/article/${announcement?.slug}`}
      >
        <a>
          <div className="rounded-button">
            <Image
              src={
                announcement?.image
                  ? `${announcement?.image}`
                  : "/placeholder.jpg"
              }
              alt={announcement?.title}
              width={782}
              height={658}
              className="rounded-button"
            />
          </div>
          <div>
            <div className={"mt-5 mx-2"}>
              <h3 className="font-extrabold text-black">
                {getTranslationContent(announcement, "title", lang)}
              </h3>
            </div>
          </div>
        </a>
      </Link>
    );
  };
  return (
    <div className="relative w-full  mt-5">
      {/* Desktop view */}
      <div className="hidden md:grid md:grid-cols-4 gap-4">
        {items?.map((announcement, index) => (
          <NewsItem key={index} announcement={announcement} />
        ))}
      </div>
      {/* Mobile slider */}
      <div className="md:hidden">
        <div
          ref={sliderRef}
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch",
          }}
          className="flex overflow-x-auto snap-x snap-mandatory scrollbar-hide"
        >
          {items.map((item, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full snap-center p-4 rounded"
            >
              <NewsItem announcement={item} />
            </div>
          ))}
        </div>
        {/* Bullet Navigation */}
        <div className={`flex justify-center mt-4`}>
          {(items || [])?.map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(isRTL ? -1 * index : index)}
              className={`h-3 w-3 rounded-full mx-1 focus:outline-none ${
                (isRTL ? -1 * currentIndex : currentIndex) === index
                  ? "bg-orange"
                  : "bg-oeaMain"
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SliderNews;
